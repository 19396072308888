/* global $ */
import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import 'bootstrap/js/dist/popover';
import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/list/main.css';
import 'bootstrap/scss/bootstrap.scss';

function postHeight(height) {
  window.parent.postMessage(`${height + 25}px`, '*');
}

document.addEventListener('DOMContentLoaded', () => {
  const eventDiv = document.getElementById('event_data');
  const eventData = JSON.parse(eventDiv.getAttribute('data'));
  const calendarEl = document.getElementById('calendar');
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );

  function linkToEvent(eventId, status) {
    if (status === 'Open') {
      return `\n<a target="_self" href="/registrations/new?event_id=${eventId}">Register here</a>`;
    }
    return `\n<a target="_self" href="/waitlists/new?event_id=${eventId}">Add to Waitlist</a>`;
  }

  function eventColor(eventType) {
    switch (eventType) {
      case 'pipe_ceremony':
        return '#ef9a9a';
      case 'medicine_ceremony':
        return '#80cbc4';
      case 'grandfather_ceremony':
        return '#b39ddb';
      case 'sponsored_ceremony':
        return '#90caf9';
      case 'other':
        return '#C5B8FF';
      default:
        return '#ef9a9a';
    }
  }

  function renderView(info) {
    const event = info.event;
    const element = info.el;

    element.style.backgroundColor = eventColor(event.extendedProps.event_type);

    $(element).popover({
      title: event.title,
      placement: 'auto',
      content:
        event.extendedProps.description +
        linkToEvent(event.extendedProps.eventId, event.extendedProps.status),
      trigger: 'focus',
      container: 'body',
      html: true,
      animation: true
    });
    $(element).attr('data-trigger', 'focus');
    $(element).attr('data-toggle', 'popover');
    $(element).attr('tabindex', '0');
  }

  const calendar = new Calendar(calendarEl, {
    timeZone: 'America/Los_Angeles',
    plugins: [dayGridPlugin, listPlugin],
    themeSystem: 'standard',
    defaultView: isMobile ? 'listMonth' : 'dayGridMonth',
    events: eventData,
    eventRender(info) {
      renderView(info);
    }
  });

  calendar.setOption('contentHeight', 'auto');
  calendar.render();

  // Post window height to parent iFrame
  // If on mobile just set the height to 600
  let docHeight =
    document.height !== undefined
      ? document.height
      : document.body.offsetHeight;

  if (isMobile) {
    docHeight = 600;
  }
  postHeight(docHeight);
});
